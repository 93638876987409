import * as THREE from "three";
import { PromiseHash, loadDAE, loadTexture } from "./utils.js";
import { SuperSimpleStateMachine } from "./StateMachine";

const BLINK_TIME = 100; //ms
const BLINK_RATE = 2000; //ms
const RANDOM_FACE_TIME = 900; //ms, the amount of time to keep a random face, must be less than BLINK_RATE
const BLINK_VARYING_RATE = 3000; //ms, BLINK_RATE - BLINK_VARYING_RATE

const IdleFaceStateMachine = SuperSimpleStateMachine.factory([
  "DEFAULT",     //normal face
  "RANDOM_FACE", //Random face just before a blink
  "BLINK",       //blinking face
], "DEFAULT");

export class GoodGoodCharacter extends THREE.Group {
    constructor(collada, faces){
        super();
        this.faces = faces;
        this.faceName = "idle"; //The current GoodGood face

        //Gets the root object of the scene
        let ggObj = collada.scene.children[0];
        ggObj.frustumCulled = false;

        //Find the skinned mesh (that's the character)
        this.bones = {}; //legacy
        this.ids = {};
        ggObj.traverse((o)=>{
            if(typeof o !== "object") {
                return;
            }
            if(o.name) {
                if(this.ids[o.name]) {
                    console.warn(`Duplicate named object ${o.name}`);
                }
                this.ids[o.name] = o;
            }
            if(o.isMesh) {
                o.frustumCulled = false; //Fixes some issues with this mesh
            }
            if(o.isBone) {
                this.bones[o.name] = o; //legacy, use .ids instead
            }
        });
        let skinnedMesh = this.ids.Body;
        //Might be able to take out now, but earlier nothing would render
        //without copying into a new material... Also don't try to render
        //the skinnedMesh without a skeleton with skinning: true
        skinnedMesh.material = new THREE.MeshPhongMaterial({
            map: skinnedMesh.material.map,
            skinning : true 
        });//This is CRITICAL to rendering the model
        //Create the mixer to play animations with and store it with the object
        this.mixer = new THREE.AnimationMixer( skinnedMesh.parent );

        this.add(ggObj);
        this.animations = {};
        collada.animations.forEach((a)=>{
            this.animations[a.name] = a;
        });

        //One of the animations is fucked up for the scale for one of the objects
        //specifically GG_NEWLETTER > group1 > group gets 0.01 scale
        //let track = this.animations["GG_NEWLETTER"].tracks.find((t)=>`${this.ids.group.uuid}.scale`)
        //track.values = track.values.map((s)=>1)
        //TODO...

        //Debug stuff
        //let helper = new THREE.SkeletonHelper( skinnedMesh.parent );
        //helper.material.linewidth = 3;
        //scene.add( helper );

        //Head rotation
        this.neckQuaternionTarget = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,0,1), 0);
        this.neckQuaternionCurrent = this.neckQuaternionTarget.clone();

        this.blinkSM = new IdleFaceStateMachine();
        this._lastTime = Date.now();
        this._nextBlinkInterval = + BLINK_RATE + Math.random()*BLINK_VARYING_RATE;
    }

    setFace(faceName, dontSaveState=false) {
        if(!this.faces[faceName]) {
            throw new Error(`GoodGood doesn't have a face "${faceName}"`);
        }
        let face = this.ids.Face; //Get the face mesh
        let faceTex = this.faces[faceName];
        face.material.map = faceTex;
        face.material.needsUpdate = true;
        if(!dontSaveState) {
            this.faceName = faceName;
        }
    }

    setNeckQuaternionTarget(quat) {
        this.neckQuaternionTarget.copy(quat);
    }

    onUpdate() {
        let time = Date.now();
        let deltaTime = time - this._lastTime;
        this._lastTime = time;

        //Update the mixer
        //Point goodgood towards the rotation target
        this.mixer.update(deltaTime/1000);

        //slerp between target and current after mixer updates
        this.ids.SheKNeck2.quaternion
            .copy(this.neckQuaternionCurrent)
            .slerp(this.neckQuaternionTarget, 0.15);
        this.neckQuaternionCurrent.copy(this.ids.SheKNeck2.quaternion);

        //blink update
        if(this.blinkSM.state === this.blinkSM.DEFAULT &&
            this.blinkSM.DEFAULT.sinceLastSeen > this._nextBlinkInterval - RANDOM_FACE_TIME) {
            this.blinkSM.state = this.blinkSM.RANDOM_FACE;

            //Just before blink, choose a random face, 33% of the time
            if(Math.random() < 0.3333) {
                let randomFaces = ["idlePupilsUp", "idlePupilsDown", "ai", "cdetc", "fv", "laugh", "smile"];
                this.setFace(randomFaces[Math.floor(Math.random()*randomFaces.length)], true);
            }
        }
        else if(this.blinkSM.state === this.blinkSM.RANDOM_FACE &&
            this.blinkSM.RANDOM_FACE.sinceLastSeen > RANDOM_FACE_TIME) {
            this.blinkSM.state = this.blinkSM.BLINK;

            this.setFace("blink", true);
        }
        else if(this.blinkSM.state === this.blinkSM.BLINK &&
            this.blinkSM.BLINK.sinceLastSeen > BLINK_TIME) {
            this.blinkSM.state = this.blinkSM.DEFAULT;
            this.setFace(this.faceName);
        }
    }

    static async load(path) {
        let collada = await loadDAE(path);

        let faces = {
            idlePupilsUp: loadTexture("res/Materials-Textures/Face_Template Var1.png"),
            idlePupilsDown: loadTexture("res/Materials-Textures/Face_Template Var2.png"),
            ai: loadTexture("res/Materials-Textures/AI_1.png"),
            cdetc: loadTexture("res/Materials-Textures/CDetc_1.png"),
            blink: loadTexture("res/Materials-Textures/Face_Template_Blink.png"),
            fv: loadTexture("res/Materials-Textures/FV_1.png"),
            laugh: loadTexture("res/Materials-Textures/Laugh_1.png"),
            smile: loadTexture("res/Materials-Textures/Smile_1.png"),
        };
        faces = await PromiseHash(faces);
        faces.idle = faces.idlePupilsUp; //Set a default idle

        return new GoodGoodCharacter(collada, faces);
    }
}