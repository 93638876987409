import Vue from "vue";

Vue.component("modal", {
  template: `
    <transition name="hide">
      <div class="modal-container"
        v-if="vif">
        <div class="modal"
          :style="{ top: modalScrollHeight + 'px' }"
          ref="modal">
          <div class="modal-close-btn"
            @click="$emit('close')">+</div>
          <slot></slot>
        </div>
      </div>
    </transition>
  `,
  props:{
    vif: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      modalScrollHeight: 0
    }
  },
  watch: {
    async vif(){
      if(!this.vif) {
        return; //Only set position on vif becoming true
      }

      await this.$nextTick();
      let scrollHeight = document.documentElement.scrollTop || document.body.scrollTop;
      let viewportHeight =  Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      let modalHeight = this.$refs.modal.getBoundingClientRect().height;

      //When vif changes, set the modal to be centered at the current scroll height
      //(Math.min with scrollHeigh so it's never above the viewport)
      this.modalScrollHeight = Math.max(scrollHeight + viewportHeight / 2 - modalHeight / 2, scrollHeight);
    }
  }
});