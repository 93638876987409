import axios from "axios";
import Vue from "vue";
import "./goodgood-text-animated";
import "./resizable-three-renderer";
import "./goodgood-card";
import "./progressive-text";
import { getRandomLetterCopy, getRandomWelcomeMessage, getAPILetter, putAPILetter, copy } from "../GoodGoodDialog";
import { SuperSimpleStateMachine } from "../StateMachine";
import { GoodGoodScene } from "../GoodGoodScene";
import { LocalizationCopy } from "../LocalizationCopy";
import { conversions, noObserve } from "../utils";

const InteractiveScreenStateMachine = SuperSimpleStateMachine.bind(SuperSimpleStateMachine, [
  "IDLE",
  "NORMAL_CARD", //A card with content from goodgood or the backend database
  "EDITABLE_CARD" //A card that the user can edit and send to the backend
], "IDLE");

const defaultCity = "Wherever";
const maxEditableLength = 240;

Vue.component("interactive-screen", {
  template: `
  <div class="interactive-screen">
    <div class="interactive-content">

      <!-- Text Bubbles -->
      <transition name="hide">
        <progressive-text
          class="text-bubble top-positioned-bubble"
          v-if="showBubble"
          :text="bubbleCopy" />
      </transition>
      <transition name="hide">
        <div
          class="letter-hover-text"
          :style="letterTooltipPosition"
          v-if="showLetterTooltip"
          v-text="letterTooltipCopy"></div>
      </transition>

      <!-- Cards -->
      <transition name="offscreen-bottom">
        <goodgood-card v-if="letterVisible">
          <button class="corner-button top-right next"
            @click="closeLetter"></button>
          <p class="text">{{letter.entry}}</p>
          <p class="name">- {{letter.name}}</p>
          <p class="city">{{letter.city}}</p>
        </goodgood-card>
      </transition>
      <transition :name="editableLetterTransition"
        @after-enter="$refs.contenteditable.$refs.contenteditable.focus()">
        <goodgood-card v-if="editableLetterVisible"
          class="purple">
          <button class="corner-button top-right skip"
            @click="closeLetter"></button>
          <contenteditable
            :placeholder="prompt"
            @keydown.enter="onEnter"
            v-model="entry"
            :maxlength="${maxEditableLength}"
            ref="contenteditable" />
          <div class="editable-letter-bottom">
            <p class="length">{{entry.length}}/${maxEditableLength}</p>
            <p class="error"
              v-if="editableError">{{editableError}}</p>
          </div>
          <p class="name">- {{name}}</p>
          <p class="city">{{city}}</p>
          <button class="corner-button bottom-right send"
            @click="onEnter"></button>
        </goodgood-card>
      </transition>

      <div class="border"></div>
      <resizable-three-renderer
        class="goodgood-scene"
        :scene="scene"
        :renderer="renderer"/>
    </div>
    <p class="before-interactive-content">
      <img src="res/GG-Logo2_Square.svg">
    </p>
    <div class="after-interactive-content">
      <goodgood-text-animated />
      <p>Let's meet next year 〠</p>
    </div>
  </div>
  `,
  props: {
    name: String,
    start: Boolean //Whether the interaction should be started (after the user opens the other window)
  },
  data(){
    const sm = new InteractiveScreenStateMachine();
    return {
      sm, //State machine
      nextState: sm.NORMAL_CARD,
      showBubble: false,
      showLetterTooltip: false,
      city: defaultCity,
      entry: "",
      letter: {
        name: "",
        city: "",
        entry: ""
      },
      bubbleCopy: undefined,
      letterTooltipCopy: "Tap Here",
      scene: undefined,
      renderer: noObserve(new THREE.WebGLRenderer()),
      prompt: copy.get("prompts.prompt1"),
      editableError: false,
      letterTooltipPosition: {top: 0, left: 0},
      letterTooltipRAFId: undefined,
      editableLetterTransition: 'offscreen-bottom'
    };
  },
  watch: {
    start(value){
      if(value) {
        this.showBubble = true;
        this.scene.startInteraction();
        //Should be available when start
        this.bubbleCopy = getRandomWelcomeMessage(this.name);
      }
    }
  },
  async mounted(){
    //Get the city from external service
    //TODO: I signed up for a token but I guess I don't need it unless I
    //hit the 1,000 reqs/day limit
    let [ipdata, letter, scene] = await Promise.all([
      axios.get("https://ipinfo.io").catch((err)=>{
        console.error(err);
        return Promise.resolve({});
      }),
      getAPILetter(),
      GoodGoodScene.load(this.renderer).then(noObserve)
      ]);
    this.city = ipdata.city || defaultCity;
    this.letter = letter;
    this.scene = scene;

    this.scene.addEventListener("letterShowing", ()=>{
      const updateLetterPosition = ()=>{
        //2D position on screen
        let v3 = conversions.projectedToViewportNDC(this.scene.letter.getWorldPosition(new THREE.Vector3()), this.scene.camera);
        let v2 = conversions.viewportNDCToViewportPX(new THREE.Vector2(v3.x, v3.y), this.renderer.domElement);
        this.letterTooltipPosition = { top: v2.y+"px", left: v2.x+"px" };
        this.letterTooltipRAFId = requestAnimationFrame(updateLetterPosition);
      };
      this.showLetterTooltip = true;
      requestAnimationFrame(updateLetterPosition);
    });
    this.scene.addEventListener("hideLetter", ()=>{
      this.showLetterTooltip = false;
      cancelAnimationFrame(this.letterTooltipRAFId);
      this.doShowLetter();
    }); //Hide the letter in the scene
    window._debug_scene = this.scene;
    await new Promise((resolve)=>setTimeout(resolve, 500)); //Delay to let the scene render a few frames before revealing it
    this.$emit("load");
  },
  computed: {
    letterVisible(){
      return this.sm.state === this.sm.NORMAL_CARD;
    },
    editableLetterVisible(){
      return this.sm.state === this.sm.EDITABLE_CARD;
    }
  },
  methods:{
    async onEnter(e) {
      e.preventDefault();
      if(!this.entry.length) {
        this.editableError = "You must enter some text.";
        return;
      }
      await putAPILetter(this.name, this.city, this.entry);
      this.closeLetter(false);
    },
    async doShowLetter(){
      this.showBubble = false;
      //Reset/set all letter data of the letter we're switching too
      if(this.nextState === this.sm.NORMAL_CARD) {
        this.letter = await getAPILetter();
      }
      else { //EDITABLE_CARD
        this.entry = "";
      }
      this.sm.state = this.nextState;

      if(this.sm.state === this.sm.NORMAL_CARD && !this.sm.EDITABLE_CARD.hasSeen) {
        //Show only once, and only after 1 normal card
        this.nextState = this.sm.EDITABLE_CARD;
      }
      else {
        this.nextState = this.sm.NORMAL_CARD;
      }
    },
    closeLetter(editableSkipped=true){
      if(this.sm.state === this.sm.NORMAL_CARD) {
        if(!this.sm.EDITABLE_CARD.hasSeen) {
          this.bubbleCopy = copy.get("character.webpromo.afterfirst");
          this.showBubble = true;
        }
        else {
          //NO MORE BUBBLE
          this.showBubble = false;
        }
      }
      else if(this.sm.state === this.sm.EDITABLE_CARD) {
        this.bubbleCopy = copy.get(`character.webpromo.aftereditable${editableSkipped ? 'skipped' : ''}`);
        this.bubbleCopy = LocalizationCopy.sprintfish(this.bubbleCopy, { name : this.name }, ["name"]);
        this.showBubble = true;
        if(!editableSkipped) { //Sending, transition to top
          //TODO: This seems to set but doesn't actually change the
          //transition properly... :/
          this.editableLetterTransition = 'offscreen-top';
        }
      }
      this.sm.state = this.sm.IDLE;
      this.scene.startInteraction();
    }
  }
});