import * as THREE from "three";
import "./THREE.additions.js";
import "three-examples/loaders/ColladaLoader.js";
import "three-examples/controls/OrbitControls.js";
import "three-examples/shaders/CopyShader.js";
import "three-examples/shaders/FXAAShader.js";
import "three-examples/postprocessing/EffectComposer.js";
import "three-examples/postprocessing/RenderPass.js";
import "three-examples/postprocessing/ShaderPass.js";

import { GradientBox } from "./GradientBox.js";
import { GoodGoodCharacter } from "./GoodGoodCharacter.js";
import { GoodGoodControls } from "./GoodGoodControls.js";
import { Letter } from "./Letter.js";
import { loadDAE, conversions, deviceOrienationToQuaternion, awaitTimeout } from "./utils.js";
import { SuperSimpleStateMachine } from "./StateMachine";

const SceneStateMachine = SuperSimpleStateMachine.bind(SuperSimpleStateMachine, [
  "IDLE_NO_CONTINUE", //Scene is idle but the user can't continue into the rest of the state machine
  "IDLE",
  "LETTER_SHOWING",
  "LETTER"
], "IDLE_NO_CONTINUE");

const playerFOV = 75;
const AUTOMATIC_LETTER_TIME = 5000; //ms
const DEFAULT_LOOK_QUATERNION = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,0,1),Math.PI/16);

/**The main scene
 * @fires GoodGoodScene#showLetter
 * @fires GoodGoodScene#showEdtiableLetter
 */
export class GoodGoodScene extends THREE.Scene {
  constructor(renderer){
    super();
    this._renderer = renderer;
    this.sm = new SceneStateMachine();
    // camera
    const rect = renderer.domElement.getBoundingClientRect();
    const aspect = rect.width / rect.height;
    this._camera = new THREE.PerspectiveCamera(playerFOV, aspect, 0.1, 1000);
    this.camera.position.set( 0, 1.2, 2 );

    // composer
    this.composer = new THREE.EffectComposer( renderer );
    const renderPass = new THREE.RenderPass( this, this.camera );
    this.composer.addPass( renderPass );
    const effectFXAA = this.effectFXAA = new THREE.ShaderPass( THREE.FXAAShader );
    effectFXAA.uniforms[ 'resolution' ].value.set( 1 / rect.width, 1 / rect.height );
    effectFXAA.renderToScreen = true;
    this.composer.addPass( effectFXAA );

    // controls
    //As of iOS 12.2, deviceorientation no longer is enabled by default (requires a really deep setting)
    //Also can't get it to work in Brave and can't get it to work in Chrome reliably...
    //so wait for an event if it comes after checking media compatibility.
    //TODO: Use a new permissions API for it if it comes out...
    let defaultControlScheme = GoodGoodControls.ControlScheme.MOUSETOUCH;
    if(!window.matchMedia("(hover:hover)").matches && window.matchMedia("(max-width: 768px)").matches) {
      //Only check for the event on non-hover supported devices (not supported on IE11 but returns false) AND
      //screen is at max 768px (so it's probably a phone)...
      defaultControlScheme = undefined; //Also don't allow mouse controls on mobile otherwise the user can't scroll down
      let self = this;
      window.addEventListener('deviceorientation', function userDeviceOrientation(){
        self.controls.setControlScheme(GoodGoodControls.ControlScheme.DEVICEORIENTATION);
        window.removeEventListener('deviceorientation', userDeviceOrientation);
      });
    }
    this.controls = new GoodGoodControls( this.camera, renderer.domElement, defaultControlScheme );
    this.controls.update();
    this.camera.quaternion.setFromAxisAngle(new THREE.Vector3(0,0,1), 0);

    renderer.domElement.addEventListener("mousemove", (e)=>{
      //Convert from screen space to 3d
      let v2 = conversions.eventToWindowPX(e);
      v2 = conversions.windowPXToViewportPX(renderer.domElement, v2);
      let ndc = conversions.viewportPXToViewportNDC(renderer.domElement, v2);
      let v3 = conversions.viewportNDCToProjected(ndc, this.camera);

      //this is the rotation we want to set it to
      let quat = new THREE.Quaternion()
          .setFromAxisAngle(new THREE.Vector3(1,0,0),Math.PI/4 * ndc.x)
          .premultiply(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,0,1),Math.PI/6 * -ndc.y));
      this.gg.setNeckQuaternionTarget(quat);
    });
    renderer.domElement.addEventListener("mouseout", (e)=>{
      this.gg.setNeckQuaternionTarget(DEFAULT_LOOK_QUATERNION);
    });

    this.noUserInteractionTimeoutID = undefined; //IF the user doesn't interact with the scene, store the timeout ID here

    // ambient light
    let am_light = new THREE.AmbientLight( 0xBBBBBB );
    this.add( am_light );
    // directional light
    let dir_light = new THREE.DirectionalLight( 0xFFFFFF );
    dir_light.position.set( 20, 30, -5 );
    dir_light.target.position.set(0,0,0);
    dir_light.castShadow = true;
    dir_light.shadow.camera.left = -30;
    dir_light.shadow.camera.top = -30;
    dir_light.shadow.camera.right = 30;
    dir_light.shadow.camera.bottom = 30;
    dir_light.shadow.camera.near = 20;
    dir_light.shadow.camera.far = 200;
    dir_light.shadow.bias = -.001
    dir_light.shadow.mapSize.width = dir_light.shadow.mapSize.height = 2048;
    this.add( dir_light );
    //Point light
    let light = new THREE.PointLight( 0xffffdd, 2, 2 );
    light.position.set(0,3,-2);
    this.add(light);

    //Color gradient box
    let box = new GradientBox();
    box.scale.set(20,8,7);
    box.position.y += 2.5;
    this.add(box);

    //Async assets
    this.gg = undefined;
    this.letter = undefined;
    this._asyncLoaded = this._loadAssets({
      gg: async ()=>await GoodGoodCharacter.load("res/GG_Animations/GG_IDLE_combined.dae"),
      letter: async ()=>await Letter.load("res/Letter/Env1.dae")
    });
  }

  static async load(...args){
    let tmp = new GoodGoodScene(...args);
    console.log("begin load");
    await tmp._asyncLoaded;
    console.log("end load");
    return tmp;
  }

  async _loadAssets(assets){
    //Capture output as the original key in the assets nd do some post processing
    Object.keys(assets).forEach((k)=>{
      assets[k] = assets[k]()
        .then((o)=>{
          assets[k] = o;
          if(o.isObject3D) {
            o.name = k;
          }
        }).catch((err)=>{
          assets[k] = err;
          console.error(err);
        });
    });

    //Await their load
    await Promise.all(Object.values(assets));
    const { gg, letter } = assets;
    this.gg = gg;
    this.gg.setNeckQuaternionTarget(DEFAULT_LOOK_QUATERNION);
    this.letter = letter;

    //Add gg and letter to the scene
    this.add(gg);
    letter.position.set(0.05,-0.1,-0.01);
    letter.quaternion.copy(
        new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,0,1),5*Math.PI/16)
            .premultiply(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,1,0),-Math.PI/16)));
    letter.visible = false;
    gg.bones.SheKRArmDigit03.add(letter);

    //Tweak animations
    gg.mixer._when( gg.animations["GG_IDLE"] );
    let idleAction = gg.mixer.clipAction( gg.animations["GG_IDLE"] );
    idleAction.clampWhenFinished = true;
    idleAction.loop = THREE.LoopRepeat;
    idleAction.weight = 0.4;
    idleAction.play();
    let newLetterAction = gg.mixer.clipAction( gg.animations["GG_LETTER"] );
    newLetterAction.clampWhenFinished = true;
    newLetterAction.loop = THREE.LoopOnce;

    //State machine
    ["touchend", "mouseup"].forEach((eventName)=>{
      this._renderer.domElement.addEventListener(eventName, async ()=>{
        clearTimeout(this.noUserInteractionTimeoutID);
        if(this.sm.state === this.sm.IDLE) {
          this.showLetter();
        }
        else if(this.sm.state === this.sm.LETTER) {
          this.hideLetter();
        }
      });
    });
    return assets;
  }

  get camera(){
    return this._camera;
  }

  /**Starts the "user interaction" portion of the scene
   */
  async startInteraction() {
    this.sm.state = this.sm.IDLE;
    this.noUserInteractionTimeoutID = setTimeout(async ()=>{
      this.showLetter();
      this.noUserInteractionTimeoutID = setTimeout(async ()=>{
        this.hideLetter();
      }, AUTOMATIC_LETTER_TIME);
    }, AUTOMATIC_LETTER_TIME);
  }

  /**Causes GoodGood to ake a letter out from behind his back
   * and present it to the user
   */
  async showLetter() {
    let idleAction = this.gg.mixer.clipAction( this.gg.animations["GG_IDLE"] );
    let newLetterAction = this.gg.mixer.clipAction( this.gg.animations["GG_LETTER"] );
    //Play the new letter animation
    this.sm.state = this.sm.LETTER_SHOWING;
    idleAction.fadeOut(1.0);
    newLetterAction.timeScale = 1;
    newLetterAction.enabled = true;
    newLetterAction.play();
    await newLetterAction.when(0.2); //seconds
    this.gg.setFace("blink");
    await newLetterAction.when(0.3); //seconds
    this.letter.visible = true; //Make the letter visible only when his hand is behind his back
    this.dispatchEvent({ type: 'letterShowing' });
    await newLetterAction.when(0.75); //seconds
    this.gg.setFace("idle");
    await newLetterAction.when("finished");
    idleAction.weight = 0.2; //Add a bit of idleAction
    idleAction
      .stopFading()
      .fadeIn(0.5); //This will reset weight to 0? so idk why the above line sets it to 0.2
    this.sm.state = this.sm.LETTER;
    /**Event fired when the letter is out and the user clicks the screen
     * @event GoodGoodScene#showLetter
     * @type {object}
     */
    this.dispatchEvent({ type: 'letter' });
  }

  /**Causes GoodGood to reset back to idle
   */
  async hideLetter() {
    let idleAction = this.gg.mixer.clipAction( this.gg.animations["GG_IDLE"] );
    let newLetterAction = this.gg.mixer.clipAction( this.gg.animations["GG_LETTER"] );
    /**Event fired when the letter is out and the user clicks the screen
     * @event GoodGoodScene#showLetter
     * @type {object}
     */
    this.dispatchEvent({ type: 'hideLetter' });
    this.letter.visible = false;
    newLetterAction.timeScale = -1;
    newLetterAction.paused = false; //Can't use .play() because bug... https://github.com/mrdoob/three.js/issues/8931
    this.sm.state = this.sm.IDLE_NO_CONTINUE;

    await newLetterAction.when("finished");
    newLetterAction.stop();
    newLetterAction.enabled = false;
    idleAction.weight = 0.4;
    idleAction.reset();
    idleAction.play();
  }

  //Fires a raycast and calls all .onRaycast handlers using normalized device coordinates
  //in a vector2
  userRaycast(ndc){
    let rc = new THREE.Raycaster();
    rc.setFromCamera(ndc, this.camera); //Converts x,y NDC and cam to a point and direction
    let hits = rc.intersectObjects(this.children, true);
    if(hits[0]) {
      let hit = hits[0];
      let foundHandler = false;
      hit.object.traverseSelfAndAncestors((o)=>{
        if(typeof o.onRaycast === "function" && !foundHandler) {
          foundHandler = true;
          o.onRaycast(hit, hits);
        }
      });
    }
  }
  
  setSize(width, height){
    this.composer.setSize( width, height );
    this.effectFXAA.uniforms[ 'resolution' ].value.set( 1 / width, 1 / height );
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }

  render(){
    if(this.gg) {
      this.gg.onUpdate();
    }
    this.composer.render();
    this.controls.update();
  }
}