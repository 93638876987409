import Vue from "vue";
import "./contenteditable";
import maskSvg from "../../../docs/res/gg_mask.svg";

Vue.component("title-screen", {
  template: `
  <div class="title-screen"
    :class="{'animate':submitted && !shouldWait, 'transparent':transparent}">
    <div class="background"></div>
    <div id="title-svg-background">
      ${maskSvg}
    </div>
    <div class="title-content">
      <div class="title-entry-container">
        <contenteditable
          :placeholder="'Type your name...'"
          :maxlength="60"
          @keydown.enter="onEnter"
          v-model="name" />
        <div class="loader" v-if="shouldWait"></div>
      </div>
      <p class="error"
        v-if="error">{{error}}</p>
    </div>
  </div>
  `,
  props : {
    transparent: {
      type: Boolean,
      default: false
    },
    shouldWait: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: "",
      submitted: false,
      error: undefined,
    }
  },
  methods : {
    onEnter(e){
      e.preventDefault();
      if(!this.name.length) {
        this.error = "You must enter a name.";
        return;
      }

      this.submitted = true;
      this.$emit('submit', this.name);
      setTimeout(()=>this.$emit('complete'), 1000);
    }
  }
});