import * as THREE from "three";

THREE.Object3D.prototype.traverseSelfAndAncestors = function(f){
  f(this);
  this.traverseAncestors(f);
}

//Crazy plumbing function to add promise based API to AnimationClip
//when("loop" || "finished"), fires when the event is called for these
//when(0.3) //Called when .time === the passed argument
//Because THREE doesnt export AnimationAction, we have to do some hacks...
THREE.AnimationMixer.prototype._when = function _when(actionName){
  let _action = this.clipAction(actionName);
  let proto = Object.getPrototypeOf(_action);
  proto.when = function which(arg){
    return new Promise((resolve, reject)=>{
      //Event bind to "finished" or "loop"
      if(typeof arg === "string" && 
        (arg.toLowerCase().trim() === "finished" ||
        arg.toLowerCase().trim() === "loop")) {
        //Bind an event that resolves one this action has looped or finished on the mixer
        this.getMixer().addEventListener(arg.toLowerCase().trim(), (e)=>{
          if(e.action === this) { resolve(e) }
        });
      }
      //When the action hits a certain time of the animation
      else if(typeof arg === "number") {
        if(arg > this.getClip().duration) {
          console.warn("The arg was greater than the clip duration and might never be hit");
        }

        let mixer = this.getMixer();
        if(!mixer.updateHook) {
          let _oldUpdate = mixer.update;
          mixer._updateHooks = [];
          mixer.addUpdateHook = function(hook){
            this._updateHooks.push(hook);
          };
          mixer.update = function(...args){
            _oldUpdate.call(this, ...args);
            this._updateHooks.forEach((f)=>f());
          };
        }
        mixer.addUpdateHook(()=>{
          let time = this.time;
          if(time > arg) {
            resolve();
          }
        });
      }
      else {
        console.error("Unknown arg " + arg);
      }
    });
  };
};