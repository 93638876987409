import * as THREE from "three";
import axios from "axios";

/**Takes an object full of promises and resolves to a new object
 * where every key is the resolved value of the promise at that key
 */
export async function PromiseHash(obj){
  let newObj = {};
  let promises = Object.entries(obj).map(([key, promise])=>{
    //Return a promise that sets the newObj[key] to the resolved value
    return promise.then((ret)=>newObj[key] = ret)
  });
  await Promise.all(promises);
  return newObj;
}

export function _assert(condition, message="Assertion Error", error=Error) {
  if(!condition) {
    throw new error(message);
  }
}

export const conversions = {
  eventToWindowPX : (ev)=>{
    if(ev instanceof MouseEvent) { //Mouse events
      return new THREE.Vector2(ev.pageX, ev.pageY);
    }
    else if(ev instanceof TouchEvent) { //Touch events
      if(ev.type === "touchend") {
        let touch = ev.changedTouches[0]; //Changed touches for touchend is the touch that was released
        return new THREE.Vector2(touch.pageX, touch.pageY);
      }
    }
    console.error("Can't handle event", ev);
    throw new Error("Can't handle event " + ev.type);
  },
  windowPXToViewportPX : (el, v2)=>{
    //TODO: Might need to take into account scrollTop
    let rect = el.getBoundingClientRect();
    return v2.sub(new THREE.Vector2(rect.left, rect.top));
  },
  viewportPXToViewportNDC : (el, v2)=>{
    v2.multiply(new THREE.Vector2(1/el.offsetWidth, 1/el.offsetHeight));
    v2.multiplyScalar(2);
    v2.sub(new THREE.Vector2(1,1));
    v2.multiply(new THREE.Vector2(1,-1));
    return v2;
  },
  viewportNDCToProjected : (v2, camera)=>{
    return new THREE.Vector3(v2.x, v2.y, 0).unproject(camera);
  },

  projectedToViewportNDC : (v3, camera)=>{
    return v3.clone().project(camera);
  },
  viewportNDCToViewportPX : (v2, el)=>{
    v2.multiply(new THREE.Vector2(1,-1));
    v2.add(new THREE.Vector2(1,1));
    v2.multiplyScalar(1/2);
    v2.multiply(new THREE.Vector2(el.offsetWidth, el.offsetHeight));
    return v2;
  },
  viewportPXToWindowPX : (v2, el)=>{
    //TODO: Might need to take into account scrollTop
    let rect = el.getBoundingClientRect();
    return v2.add(new THREE.Vector2(rect.left, rect.top));
  }
};

/**Correlary to JQuery's $.parseHTML for plain js
 * @param {string} The HTML to convert to elements
 * @returns {DocumentFragment} A document fragment that can be
 * added directly to DOM with appendChild
 */
export function parseHTML(str) {
  let parser = new DOMParser(),
  doc = parser.parseFromString(str, "text/html"),
  documentFragment = document.createDocumentFragment();
  Array.from(doc.body.children).forEach((el)=>{
    documentFragment.appendChild(el);
  });
  return documentFragment;
}

/**Loads a DAE using ColladaLoader. Make sure you
 * clean it with cleanDAE in the root of this
 * project first
 * @param {string} fp The filepath of the model
 * @returns {object} Collada object with a shit ton of keys
 */
export async function loadDAE(fp){
  let cl = new THREE.ColladaLoader();
  let resp = await axios.get(fp);
  return cl.parse(resp.data);
}

export async function loadTexture(fp){
  let tl = new THREE.TextureLoader();
  return await new Promise((resolve, reject)=>{
    tl.load(fp, resolve, undefined, reject)
  });
}

export async function awaitTimeout(timeout) {
  return await new Promise((resolve)=>setTimeout(resolve, timeout));
}

/**Builds a bounding box for a given object
 * @param {THREE.Mesh} mesh
 * @returns {THREE.Mesh} Bounding box object
 */
export function makeHitBox(mesh){
  let geo = mesh.geometry;
  if(!geo.boundingBox) {
    geo.computeBoundingBox();
  }
  let size = geo.boundingBox.getSize(new THREE.Vector3());
  let box = new THREE.BoxBufferGeometry(size.z, size.x, size.y);
  let mat = new THREE.MeshBasicMaterial({
      color: 0xFFFF00,
      visible: false //Don't render, but still get raycasts
  });
  let bb = new THREE.Mesh(box, mat);
  bb.position.add(new THREE.Vector3(0,size.x/2,0))
      .add(mesh.position);
  bb.name = (mesh.name || mesh.uuid) + "_hitbox";
  return bb;
}

const degtorad = Math.PI / 180; // Degree-to-Radian conversion
//https://www.w3.org/TR/2016/CR-orientation-event-20160818/
export function deviceOrienationToQuaternion( alpha, beta, gamma ){
  var _x = beta  ? beta  * degtorad : 0; // beta value
  var _y = gamma ? gamma * degtorad : 0; // gamma value
  var _z = alpha ? alpha * degtorad : 0; // alpha value

  var cX = Math.cos( _x/2 );
  var cY = Math.cos( _y/2 );
  var cZ = Math.cos( _z/2 );
  var sX = Math.sin( _x/2 );
  var sY = Math.sin( _y/2 );
  var sZ = Math.sin( _z/2 );

  //
  // ZXY quaternion construction.
  //

  var w = cX * cY * cZ - sX * sY * sZ;
  var x = sX * cY * cZ - cX * sY * sZ;
  var y = cX * sY * cZ + sX * cY * sZ;
  var z = cX * cY * sZ + sX * sY * cZ;

  return new THREE.Quaternion(x, y, z, w);
}

export function noObserve(obj) {
  //Mark all the properties as non-configurable and Vue won't iterate over them
  //and make them all observable
  Object.keys(obj).forEach((key)=>{
    Object.defineProperty(obj, key, { configurable: false });
  });
  return obj;
}