export class LocalizationCopy {
  constructor(obj) {
    this._copy = obj;
  }

  /**@param {object} Object where each property is a dot path to a deeply nested property
   * mapped to strings
   * @returns {object} A tree of javascript objects with keys corresponding to the
   * above format
   */
  static fromDotList(keyObj) {
    let copy = {};
    Object.keys(keyObj).forEach((key)=>{
      let currObj = copy;
      key.split(".").forEach((prop,idx,arr)=>{
        if(idx !== arr.length-1) {
          //Traverse all the way up to the last prop
          //creating objects if they don't exist
          currObj[prop] = currObj[prop] || {};
          currObj = currObj[prop];
        }
        else {
          //Set the last prop to have the copy value
          //at that path
          currObj[prop] = keyObj[key];
        }
      });
    });
    return copy;
  }

  get(propPath) {
    let ret = this._copy;
    try {
      propPath.split(".").forEach((key)=>{
        ret = ret[key];
      });
    }
    catch(e) {
      if(e instanceof TypeError) {
        console.error(`Couldn't find path ${propPath}`, this._copy);
      }
      throw e;
    }
    return ret;
  }

  //Returns all keys that match a path where the last key
  //is actually a regex
  getKeys(propPath) {
    let propSplit = propPath.split(".");
    let lastPropRegex = new RegExp(propSplit.pop());
    let obj = this.get(propSplit.join("."));
    return Object.keys(obj)
      .filter((prop)=>prop.match(lastPropRegex))
      .map((prop)=>`${propSplit.join(".")}.${prop}`);
  }

  /**Takes a list of paths, gets all the phrases at that path, and
   * picks a random one and returns [path, phrase])
   * @todo This runs getKeys on each path, but maybe we should do that
   * outside of this function and let it pass get() too?
   */
  randomPathInGroup(paths) {
    paths = paths
      .map((path)=>this.getKeys(path))
      .reduce((acc,item)=>acc.concat(item), []);

    let randomPathIdx = Math.floor(Math.random()*paths.length);
    return paths[randomPathIdx];
  }

  /**Kinda like sprintf but infers the properties based on the last part
   * in the path, or uses an explicitly passed order
   * @param {string} template
   * @param {object} context
   * @param {string|string[]} pathPartOrOrder Pass a string in which 
   * @returns {string} Filled template string
   */
  static sprintfish(template, context, pathPartOrOrder) {
    let order = pathPartOrOrder; //Default to order is an array
    //Use pathpart to try to infer the order of parameters
    if(typeof pathPartOrOrder === "string") {
      if(pathPartOrOrder.startsWith("nameint")) {
        order = ["name", "int"];
      }
      else if(pathPartOrOrder.startsWith("name")) {
        order = ["name"];
      }
      else if(pathPartOrOrder.startsWith("locationname")) {
        order = ["location", "name"];
      }
      else if(pathPartOrOrder.startsWith("location")) {
        order = ["location"];
      }
    }
    order.forEach((property)=>{
      let which = property === "int" ? "%i" : "%s";
      if(!context[property]) {
        throw new Error(`Context could not fill property "${property}"`);
      }
      template = template.replace(which, context[property]);
    });
    return template;
  }
}