import Vue from "vue";

Vue.component("progressive-text", {
    template: `
    <p v-text="partialText"></p>
    `,
    props: {
        text: {
            type: String,
            default: ""
        },
        interval: {
            type: Number,
            default: 15
        }
    },
    data(){
        return {
            currentLetter:0,
            _textInterval:undefined,
        };
    },
    computed:{
        partialText(){
            return this.text.slice(0,this.currentLetter);
        }
    },
    watch:{
        text(){
            this.restartProgressiveText();
        }
    },
    mounted(){
        this.restartProgressiveText();
    },
    methods: {
        restartProgressiveText(){
            clearInterval(this._textInterval);
            this._textInterval = setInterval(()=>{
                this.currentLetter++;
                if(this.currentLetter === this.text.length) {
                    clearInterval(this._textInterval);
                }
            }, this.interval);
        }
    }
});