export default {
  "prompts.prompt1":"“Who’s a person that has had a most profound impact on your life?” Start typing!",

  "character.webpromo.afterfirst" : "Your turn, friendo! Write a lil’ thoughtful reply to this next letter, and I’ll share it with good folks around the world...",
  "character.webpromo.aftereditable" : "Thank ya, %s! Every letter, no matter what, makes the globe a bit better. I’ve got more — ‘cause it’s mail time!",
  "character.webpromo.aftereditableskipped" : "Got nothin’? No worries, amigo. That ship’ll sail when you’re good and ready. It’s mail time!",


  "character.welcome.morning":"Good morning!",
 
  "character.welcome.afternoon":"Good afternoon!",
 
  "character.welcome.night1":"Good night, huh!",
  "character.welcome.night2":"Good night!",
 
  "character.greeting1":"It's GoodGood here!",
  "character.greeting2":"You’re as cute as a dog!",
  "character.greeting3":"Internettin connection — bringin’ us together, is what it’s supposed to be all about!",
  "character.greeting4":"Do you think I’ll ever get through all this mail?",
  "character.greeting5":"Welcome to our space! Social media without the commodity, nuh-uh!",
  "character.greeting6":"Lovin’ eachother is easy!",
  "character.greeting7":"I heard from upstairs that 'Augmented Reality' is the real meat n’ potates to this whole operation… whatever that is!",
  "character.greeting8":"Me and you. Partners in crime. Unlikely duo, changing the globe a letter at a time.",
  "character.greeting9":"Back again! And I’ve gotta whole bag of mail to boot!",
  "character.greeting10":"I’m not in the bathroom, am I?",
  "character.greeting11":"Ready to pen-pal?",
  "character.greeting12":"We make a good team. Ever considered getting into… the digital mail industry?",
  "character.greeting13":"Welcome back to the only app that doesn’t sell your private info to BadBads.",
  "character.greeting14":"Welcome back to the only app that won’t sell you out for a quick buck! I’m your host, GoodGood. We’ve met.",
  "character.greeting15":"All our closest friends, round the globe, pen-pallin’! Music to my ears. Do I have ears?",
  "character.greeting16":"Some people have written that I’m “too positive”. I read this as, 'you’re too good', and, you can't have too much of a GoodGood thing!",
  "character.greeting17":"Welcome back!",
  "character.greeting18":"Is it the 20’s? Cause when you’re gone I’ve got the great depression! Uh oh, I’m thinkin’ about the economy again…",
  "character.greeting19":"With all this goodness, let's somethin’ about eachother!",
  "character.greeting20":"BOOM. Global happiness increased with just your very presence!",
  "character.greeting21":"I’m probably up all hours, no doubt! I mean, I literally can’t sleep.",
  "character.greeting22":"If you see any mail flyin’ around, SNAG IT, will ya? It could be SUPER important — for anyone around the world!",
  "character.greeting23":"Ready to roll today? There’s a lot goin’ on in our little community!",
  "character.greeting24":"No hate here, goodness from our beautiful corner of the internet!",
  "character.greeting25":"Today IS lookin’ up! Uh-huh.",
  "character.greeting26":"%s! Your pen-pals await!",
  "character.greeting27":"All good!",
  "character.greeting28":"And they said “letter writing is dead” in mail training… Psh!",
  "character.greeting29":"My actual name is MiyagiMiyagi. Ready to practice the ancient mail time art of pen palling?",
  "character.greeting30":"Storytime. It’s like spreadin’ love butter. I hope that’s not a euphemism for something.",
  "character.greeting31":"Let’s unite the whole wide world. It’s GoodGood time!",
  "character.greeting32":"Everyone around the world, baby! This is international! Let’s see what’s happening today…",
  "character.greeting33":"Puttin’ out one good thought today means a positive change to someone else. That’s what we’re all about here!",
  "character.greeting34":"You + me. It’s like poetry.",
  "character.greeting35":"Friends don’t show friends ads. Remember, you’re worth more than your apps give you credit for.",
  "character.greeting36":"You're here! Entered from stage tight!",
  "character.greeting37":"News flash! So many people love what you’re puttin’ out there. Keep doing you, baby!",
  "character.greeting38":"I feel lucky to see your gifts firsthand.",
  "character.greeting39":"Hi welcome back, I’m GoodGood and I love you, everyone, and mail. But you know this.",
  "character.greeting40":"Oh snap! You know that letter you wrote yesterday? It made a buncha people smile. I counted!",
  "character.greeting41":"Big dream? Me, you, and the whole globe smilin' wide. Oh, and pushing up that Global Happiness to the MAX!",
  "character.greeting42":"Wrestling the ambition to write a letter — let it fly, doggy!",
  "character.greeting43":"Smell that, friendo? That’s social changin’. We’re radicals now! Pirates of the social sphere. All outta the goodness of all our digital hearts!",
  "character.greeting44":"We’re comfy here! You comfy? I comfy.",
  "character.greeting45":"Change is right here! We’re making the happy we’ve been seekin’!",
  "character.greeting46":"Happymakin’. That’s on the deck for the day!",
  "character.greeting47":"Man, I wish I could eat. Chowder looks so good. DUDE!",
  "character.greeting48":"I get it, I’m a bit big-headed. You don’t have to say nothin’! It’s the hat, optical dillusions, promise.",
  "character.greeting49":"You made it — I knew you would!",
  "character.greeting50":"I know what you’re thinking. GoodGood — how do you eat? Well, your battery, kiddo. It’s a drainin’. What can I say.",
  "character.greeting51":"Another day, another good one!",
  "character.greeting52":"WHAT! It’s a mess in here — didn’t expect you back so soon! Sorry, friend!",
  "character.greeting53":"Social media all day and no play makes GoodGood a BadBad.",
  "character.greeting54":"The positivity here is infectious — if GoodGoodness was a virus, I’ve got the plague! Help!",
  "character.greeting55":"Would you just look at your hair today.",
  "character.greeting56":"Ciao, baby!",
  "character.greeting57":"Battery drain makes me… sleepy…",
  "character.greeting58":"If I’ve learned one thing on this job, the world is full of nice people, like you.",
  "character.greeting59":"I know it’s easy to get caught up in drama and let stinkers get under our skin, but I promise — there’s a whole lotta happy here!",
  "character.greeting60":"Global goodwill is lookin’ HOT today — let’s take it up!",
  "character.greeting61":"Good things come from GoodGood places. That’s a Confucius, probably.",
  "character.greeting62":"I miss my dog.",
  "character.greeting63":"We’re all growing together… I think that this is a good place to grow. I’m gonna be 6’4”, any time now.",
  "character.greeting64":"We are all students of how to be nicer to one another. GoodGood’s school of GoodGood Grads.",
  "character.greeting65":"You got a whole lot of heart — I proofread one of your letters! I’m so glad you shared!",
  "character.greeting66":"Major Schmaltz, coming in hot.",
  "character.greeting67":"Every letter from you has made a positive impact on someone, and that’s worth somethin’!",
  "character.greeting68":"It might be hard on the internet sometimes… but I hope we all prove that we’re all good at heart. It’s why we’re here, right?!",
  "character.greeting69":"SKIDOO!",
  "character.greeting70":"I think we need a whole lot more GoodGood to counter the BadBad these days. It’s in you, the docta’s orders!",
  "character.greeting71":"Oh-Oh, pro-tip from yours truly — read the prompt right as I think of it at sunrise! Gets ya thinking happy the whole day!",
  "character.greeting72":"I’m always just a tap away — And I'm always IN the pocket!",
  "character.greeting73":"What am I scared of most? Airplane mode.",
  "character.greeting74":"You’ve been doing so good! I don’t know if anyone has said anything lately, but I sure think so!",
  "character.greeting75":"Don’t ever swear! I’ll be the one who gets a swift spank from Notification Central for that!",
  "character.greeting76":"I know how hard you must be working lately. You should be real proud of that, no doubt!",
  "character.greeting77":"Gotta be diligent in our work to make people feel good, included, and welcome! We’re all GoodGoods.",
  "character.greeting78":"Between you and me, I wish they would’ve programmed a toilet in this place.",
  "character.greeting79":"It’s nice to lie in the sun, and just do nuthin’.",
  "character.greeting80":"So, did they ever explain what the “i” stands for? Personally, I think it’s for the 'I' in 'MAIL!'",
  "character.greeting81":"What is LTE anyway? Why does it throttle like a hot rod? VROOM VROOM, data.",
  "character.greeting82":"The major upside to our friendship is not having to reach up to those icons with my tiny lil’ arms anymore!",
  "character.greeting83":"I know it’s hard to not-think about some of the BadBad in life. When you’re here, I hope it makes it a bit better!",
  "character.greeting84":"Here I am! No breaks, no vacations, no… benefits? Just mail! Mail and fine dining.",
  "character.greeting85":"We’re living through history, buddy! I hope you know that you’re my favorite part of this corner of it all...",
  "character.greeting86":"We ARE the Internet! Good things — what it was made for! Yeah yeah yeah!",
  "character.greeting87":"Speedwalkin’ today, huh? I’m rattlin’ all up and over in here!",
  "character.greeting88":"Do you ever go, “Hey, GoodGood lives in my phone — maybe I should be gentle with it” when you wrastle me all over creation?",
  "character.greeting89":"Are you the chromatic scale system because when you’re around my mood keeps on goin’ up!",
  "character.greeting90":"When was the last time I told you how good you are? You’re really somethin’ else!",
  "character.greeting91":"Ok-Ok, let’s have some fun already…",
  "character.greeting92":"LIVE: You and me, augmentin’ some GoodGoodness tout suite in your space, ace.",
  "character.greeting93":"Exciting day ahead! We're going to talk about the ECONOMICS OF THE DIGITAL MAIL INDUSTRY...Oh wait...That's not right...",
 
  "character.greeting.name1": "Hello %s, nice to meet you!",
  "character.greeting.name2":"What’s new, %s? I feel somethin’ special today!",
  "character.greeting.name3":"I couldn’t wait to see you, %s! Givin’ me a break from all those notifications to deliver!",
  "character.greeting.name4":"You know %s, we’re not so different, yous and mes. We both have… fingers. And MAIL!",
  "character.greeting.name5":"You’re back %s! Today we’re cookin’ with gas — YEAH!",
  "character.greeting.name6":"You’re back, %s! Pallin’ with pens. Er — pen-palling.",
  "character.greeting.name7":"New letters, straight to your mailbox! Because I like you, %s! And… also because it’s my job.",
  "character.greeting.name8":"%s, I’m so glad you’re a part of our go-doer do-gooder GoodGooders. Club name pending…",
  "character.greeting.name9":"Let’s do this again, %s!",
  "character.greeting.name10":"Every little story we share is a positive motion forward, %s — it’s the bigger picture, yo!",
  "character.greeting.name11":"%s, I hope you know how much I love these daily words and stories.",
  "character.greeting.name12":"Do you have a dog, %s? I can smell it.",
  "character.greeting.name13":"I missed you! I’ve been reading all this mail with your name on it, %s!",
  "character.greeting.name14":"Let’s see what’s in store for us today, shall we, %s?",
  "character.greeting.name15":"Welcome, %s. I hope you’re happy!",
  "character.greeting.name16":"I’m thankful for a lot of things, people I meet, and I know %s is up there!",
  "character.greeting.name17":"Dude. %s. You’re not going to believe what’s in store for today.",
  "character.greeting.name18":"I’m so glad we met, %s!",
  "character.greeting.name19":"I’ve been thinkin’, %s. I hope you see the good I see through that little screen of ours. And that’s all…",
  "character.greeting.name20":"Look at the face on %s — DaVinci couldn’t paint somethin’ as pretty!",
  "character.greeting.name21":"All-aboard, we’re on a one-way ticket to today’s topic. You and me, %s!",
  "character.greeting.name22":"I’m always down to spend quality time with %s!",
  "character.greeting.name23":"And I was just thinkin’ about %s's face!",
  "character.greeting.name24":"I’m grateful for all these letters and stories. We’re working towards something special, huh %s?",
  "character.greeting.name25":"Listen %i, you know I love working here… but it does get a bit moist in your pocket…",
  "character.greeting.name26":"Isn’t it great to wake up to something just happy? It’s just a positive place, right here. Thanks, %s!",
  "character.greeting.name27":"Haven’t seen you in a while, %s! I assumed you’ve just been kickin’ it!",
  "character.greeting.name28":"This day in history… %s met GoodGood!",
  "character.greeting.name29":"Thanks for helping, %s!",
  "character.greeting.name30":"Haven’t seen you in a while, %s — I know it’s easy to get caught up in real things, I hope we can escape a bit!",
  "character.greeting.name31":"You’re back %s! More like Babyback DIBS on spilling today’s topic for you… yeesh.",
  "character.greeting.name32":"Back for more? Yes, %s!",
  "character.greeting.name33":"Look at you! What an entrance, %s!",
  "character.greeting.name34":"It’s a pleasure, gettin’ to know the one-and-only %s!",
  "character.greeting.name35":"I’m so glad you’re back, %s! Let's roll!",
  "character.greeting.name36":"%s, I don’t know no one quite like you.",
  "character.greeting.name37":"BOOM. And they're back! Welcome to the party, %s.",
  "character.greeting.name38":"Subject line: %s’s back and ready to write somethin’ HOT and WHOLESOME. Send!",
  "character.greeting.name39":"Can we take it up? Let’s take it up today, %s!",
  "character.greeting.name40":"%s, y'know you how many people would love to read the letters you write? I think you’re somethin’ else!",
 
  "character.greeting.location1":"Feelin’ some major %s-pride today.",
  "character.greeting.location2":"I’m AMPED I get to visit %s! Crossing this one off the list!",
  "character.greeting.location3":"Sometimes… I dream about quittin’ Notification Central and settling down in %s. As much as I love your pocket…",
  "character.greeting.location4":"Let’s see what today brings here in %s! You ready?",
  "character.greeting.location5":"It’s a bit chilly in %s. And I’m in a whole suit and everything!",
  "character.greeting.location6":"Mom always told me about %s! Great Uncle PopPop was from here, I think. Rest in piece…",
  "character.greeting.location7":"You know, I remember seeing %s in that one really good movie… with that actor… you know?",
  "character.greeting.location8":"I know the globe seems pretty big from %s, but we’re just one neighborhood. Across the sea? It’s next door to me…",
  "character.greeting.location9":"So this is %s, huh? It was a lot… flatter… in the mail.",
  "character.greeting.location10":"If you dug a hole through the globe from %s, that’s where I got a letter from earlier! I’m glad my job is to read the mail — not dig for it!",
  "character.greeting.location11":"%s! No where I’d rather be!",
 
  "character.greeting.locationname1":"Are all people from %s such good storytellers, or is that just a %s thing?",
 
  "character.greeting.nameint1":"Ah, %s, you’re back! There’s like, %i people sharing right now!",
  "character.greeting.nameint2":"%s, while you were gone, I sorted through like %i letters and boy let me tell you people, really don’t get the Oxford comma.",
  "character.greeting.nameint3":"There are like, over 100 people chatting in Michigan right now-now.",
  "character.greeting.nameint4":"Hey %s, I know it’s easy to feel alone, even with %i of your closest friends here! But I’m always here, promise!",
  "character.greeting.nameint5":"I’ve been busy, %s — you know how many letters I’ve sent last month? %i!",
 
  "character.greeting.inspoquote1":"Like Stephen King says, “get busy living or get busy dying.” — uh, right?",
  "character.greeting.inspoquote2":"Like ol’ Honest Abe used to say “I’m a success today because I had a friend who believed in me and I didn’t have the heart to let him down.” — that friend is me! Don’t let me down!",
  "character.greeting.inspoquote3":"Y’know guy who came up with all this, Stevey Jobs, used to say something like “don’t be trapped living with the results of other people’s thinking.” Let’s be creative, do something new here!",
  "character.greeting.inspoquote4":"Practicing quote-making… “a well-told story is life with all the spices” — me, GoodGood.",
  "character.greeting.inspoquote5":"“If you wanna make the world a better place, take a look atcho self an’ then make a change” hoo! — like the one and only MJ.",
  "character.greeting.inspoquote6":"I’m all maize… you should try it!  You’ll “keep your face to the sunshine and you can never see the shadow.” — as Helen used to say.",
  "character.greeting.inspoquote7":"You know what Abe Lincoln used to say? “When I do good, I feel good; when I do bad, I feel bad, and that is my religion.” Do some good, good!",
  "character.greeting.inspoquote8":"Hey, I can’t do everything here — Picasso put it best when he said “computers are useless. They can only give you answers!” I need your creative brain, too!",
  "character.greeting.inspoquote9":"Like Twain said, “the difference between the right word and the almost right word is the difference between lightning and a lightning bug.” — and like Twain said, there’s a whole lotta difference between a good letter and a good-good letter!",
  "character.greeting.inspoquote10":"Like Wolfgang used to say …“letters are among the most significant memorial a person can leave behind them.” Let’s get writing, you!",
  "character.greeting.inspoquote11":"“More than kisses, letters mingle souls” — John Donne. That’d make this place a right mixer!",
  "character.greeting.inspoquote12":"“Letters are something from you. It’s a different kind of intention than writing an email.” I think we all can learn something from Keanu Reeves.",
  "character.greeting.inspoquote13":"“Letters are expectation packaged in an envelope” — Shana Alexander. Here, the expectation is good-goodness!",
  "character.greeting.inspoquote14":"“To send a letter is a good way to go somewhere, without moving anything but your heart.” — Phyllis Grissim-Theroux",
  "character.greeting.inspoquote15":"“Letter writing is the only device combining solitude with good company.” — Lord Byron",
  "character.greeting.inspoquote16":"“Don't you like to write letters? I do because it's such a swell way to keep from working and yet feel you've done something.” — Ernest Hemingway",
  "character.greeting.inspoquote17":"I’m feeling like Virginia Woolf over here. “You have a touch in letter writing that is beyond me. Something unexpected, like coming round a corner in a rose garden and finding it still daylight.”",
  "character.greeting.inspoquote18":"“Real letter-writing ... is founded on a need as old and as young as humanity itself, the need that one human being has of another.” — Agnes Repplier",
  "character.greeting.inspoquote19":"“A letter is an unannounced visit, the postman the agent of rude surprises. One ought to reserve an hour a week for receiving letters and afterwards take a bath.” —Nietzsche. Hey, wait a second!",
  "character.greeting.inspoquote20":"We’re storytellers here! As ol’ Ben Franklin would say, “Either write something worth reading or do something worth writing.”",
  "character.greeting.inspoquote21":"Let’s read a story from somebody’s different perspective… Proust always said “the real voyage of discovery consists not in seeking new lands but seeing with new eyes.”",
  "character.greeting.inspoquote22":"You had me thinkin’ of my favorite quote: “I hate quotations. Tell me what you know.” — Ralph Waldo Emerson",
  "character.greeting.inspoquote23":"You might as well write, even ‘bout nothing! “Wisdom comes to each of us when we realize how little we understand about life, ourselves, and the world around us.” So Socratic.",
  "character.greeting.inspoquote24":"I would love to change the world, but they won’t give me the app permissions.",
  "character.greeting.inspoquote25":"Let’s think about others today. “Who looks outside, dreams; who looks inside, awakes.” — Jungian philosophy for you.",
  "character.greeting.inspoquote26":"Here, we’re on the positive side of the web, besides, by Shultzian logic” “don’t worry about the world coming to an end today. It’s already tomorrow in Australia.”",

  "character.arkit.mailtime.newletters1":"Now, I've got new letters for you!",
  "character.arkit.mailtime.newletters2":"Now, letters for %s, incoming!",
  "character.arkit.mailtime.newletters3":"Now, here's what's been written just for you, today!",
  "character.arkit.mailtime.newletters4":"Now, new letters from people around the world, for you!",
  "character.arkit.mailtime.newletters5":"Now, new letters... you know what that means? New pen-pals!",
  "character.arkit.mailtime.newletters6":"Now, you've got mail, %s!",
  "character.arkit.mailtime.newletters7":"Now, comin' right from the mailbox, new letters!",
  "character.arkit.mailtime.newletters8":"Now, It's that time again, %s... New letters!",
  "character.arkit.mailtime.newletters9":"Now, let's see what new letters we have for you, shall we?",
  "character.arkit.mailtime.newletters10":"Now, time for new letters!",
  "character.arkit.mailtime.newletters11":"Now, new letters - you know the drill!",
  "character.arkit.mailtime.newletters12":"Now, I've got a stack of today's new letters.",
  "character.arkit.mailtime.newletters13":"Now, let's read these new letters written for you.",
  "character.arkit.mailtime.newletters14":"Now, new letters for you! I hope you like 'um!",
  "character.arkit.mailtime.newletters15":"Now, for some hot new letters!",
  "character.arkit.mailtime.newletters16":"Now, what's that smell? How 'bout all these new letters!",
  "character.arkit.mailtime.newletters17":"Now, just for %s, new letters!",
  "character.arkit.mailtime.newletters18":"Now, for what I look forward to most each day - new letters!",
  "character.arkit.mailtime.newletters19":"Now, some new letters!",
  "character.arkit.mailtime.newletters20":"Now, new letters, %s!",
  "character.arkit.mailtime.newletters21":"Now, for 5 brand-spankin' new letters...",
  "character.arkit.mailtime.newletters22":"Now, it's time to read some mail from around the globe!",
  "character.arkit.mailtime.newletters23":"Now, Take these good new letters for you!",
  "character.arkit.mailtime.newletters24":"Now, let's see... yep! New letters for %s!",
  "character.arkit.mailtime.newletters25":"Now, for %s! These have your name on them!",
  "character.arkit.mailtime.newletters26":"Now, I picked out some new letters for you!",
  "character.arkit.mailtime.newletters27":"Now, see if you like these new letters I found for you.",
  "character.arkit.mailtime.newletters28":"Now, let's open some mail!",
  "character.arkit.mailtime.newletters29":"Now, time to read some new letters!",
  "character.arkit.mailtime.newletters30":"Now, for some good letters from some good people on this good globe...",
  "character.arkit.mailtime.newletters31":"Now, you know what time it is! I've got new letters for you!",
  "character.arkit.mailtime.newletters32":"Now, special new letters from new pen-pals!",
  "character.arkit.mailtime.newletters33":"Now, I picked out some new letters for you!",
  "character.arkit.mailtime.newletters34":"Now, new letters, hand-picked!",
  "character.arkit.mailtime.newletters35":"Now, new letters for %s! I'm so excited to see what's up!",
  "character.arkit.mailtime.newletters36":"Now, let's see what's happenin' in these new letters...",
  "character.arkit.mailtime.newletters37":"Now, I've sorted out some new letters just for you - see? '%s', right on it!",
}