import Vue from "vue";
import * as THREE from "three";
import Stats from "stats.js";

let queryString = window.location.href.split("?")[1];
const debugMode = queryString && queryString.indexOf("debug") !== -1;

/**
 * @prop {THREE.WebGLRenderer} renderer
 */
Vue.component("resizable-three-renderer", {
  template: `
    <div class="resizable-three-renderer" ref="root"></div>`,
  props: {
    scene: THREE.Scene,
    renderer: THREE.WebGLRenderer
  },
  watch: {
    //TODO: Once this is set it can't be changed
    scene(value){
      this.renderLoop();
    }
  },
  async mounted(){
    this.$refs.root.appendChild(this.renderer.domElement);
    window.addEventListener("resize", this.resize);
    this.resize();

    let queryString = window.location.href.split("?")[1];
    if(debugMode) {
      this.stats = new Stats();
      this.$refs.root.appendChild(this.stats.dom);
    }
  },
  methods:{
    renderLoop: (debugMode ? function(){
      this.stats.begin();
      this.scene.render();
      this.stats.end();
      requestAnimationFrame(this.renderLoop.bind(this));
    } : function(){
      this.scene.render();
      requestAnimationFrame(this.renderLoop.bind(this));
    }),
    resize(){
      this.renderer.domElement.setAttribute("style", "");
      let rect = this.renderer.domElement.getBoundingClientRect();
      this.renderer.setSize( rect.width, rect.height );
      if(this.scene){
        this.scene.setSize(rect.width, rect.height);
      }
    }
  }
});