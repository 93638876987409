import Vue from "vue";
import AsyncComputed from 'vue-async-computed';
import './components/landing-page.js';

Vue.use(AsyncComputed);

window.addEventListener("DOMContentLoaded", ()=>{
  new Vue({
    el: "#content",
    template: `
      <main id="content">
        <landing-page />
      </main>
    `
  });
});