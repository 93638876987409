export default {
    data(){
        return {
            isDesktop: false,
            _resizeHandler: undefined
        };
    },
    mounted(){
        this.checkIsDesktop();
        this._resizeHandler = this.checkIsDesktop.bind(this);
        window.addEventListener('resize', this._resizeHandler, {
            passive: true
        });
    },
    destroyed(){
        window.removeEventListener('resize', this._resizeHandler);
    },
    methods:{
        checkIsDesktop(){
            this.isDesktop = window.matchMedia("screen and (min-width : 768px)").matches;
        }
    }
};