import * as THREE from "three";
import { loadDAE } from "./utils.js";

export class Letter extends THREE.Group {
    constructor(collada){
        super();

        let letterObj = collada.scene.children[1];
        letterObj.scale.set(0.001,0.001,0.001);
        letterObj.traverse((o)=>{
            if(typeof o !== "object") {
                return;
            }
            if(o.isMesh) {
                this.mainMesh = o;
            }
        });
        this.add(letterObj);
    }

    static async load(path) {
        let collada = await loadDAE(path);
        console.log(collada);
        return new Letter(collada);
    }
}