import Vue from "vue";
import axios from "axios";
import "./modal.js";
import "./title-screen.js";
import "./interactive-screen.js";
import "./resizable-three-renderer.js";
import { GoodGoodSnoozeScene } from "../GoodGoodSnoozeScene.js";
import { noObserve } from "../utils.js";
import DesktopMixin from "../mixins/desktop.js";

const goodgoodQuestions = [
  "Have you ever stuck up for someone when they needed help?",
  "Why do you think people do or say mean things to others online?",
  "If you could give all human beings one virtue, which on would you choose?",
  "Where is your favorite place to be when you're by yourself?",
  "How did we come to be on this planet, what motivated us to do this?",
  "What is a significat moment in your life that you are proud of?"
];

Vue.component("landing-page", {
  el: "#content",
  template: `
    <div class="landing-page">
      <transition name="hide">
        <div v-if="showScrollDownArrow"
          class="scroll-down-arrow"></div>
      </transition>

      <div class="goodgood-experience">
        <interactive-screen
          @load="onLoad"
          :name="name"
          :start="finishedTitle"></interactive-screen>
        <title-screen
          @submit="onSubmit"
          @complete="onComplete"
          :transparent="loaded"
          :shouldWait="submittedTitle && !loaded"
          v-show="!finishedTitle"></title-screen>
      </div>
      <div class="page-content">
        <!--Content where the backround flows around the dashed line-->
        <div class="dashed-line-content">
          <img class="background-line" src="res/page_content/Env-Path.svg" alt="Letter path">
          <img class="floating-letter floating-letter-1" src="res/page_content/Float-Letters1.png" alt="Letter on path">
          <img class="floating-letter floating-letter-2" src="res/page_content/Float-Letters1.png" alt="Letter on path">
          <section class="title">
            <h2>Be the GoodGood you want to see in the world</h2>
              <transition-group name="bubble-list" tag="div" class="gradient-bubbles">
                <div v-for="bubbleObj in activeBubbles"
                  class="gradient-bubble"
                  :class="bubbleObj.cls"
                  v-text="bubbleObj.question"
                  :key="bubbleObj.question.slice(0,20)"></div>
              </transition-group>
            <resizable-three-renderer
              class="goodgood-snooze-scene"
              :renderer="renderer"
              :scene="scene"/>
          </section>

          <section class="text-section-1">
            <h3 class="a-new-good-friend-headline">A new good friend</h3>
            <p>As “alive” as you or me, GoodGood is an upbeat digital mailman who lives inside your phone and whose job is to deliver notifications to you.</p>
            <div class="hr"></div>
            <p>Lately, GoodGood has been overwhelmed by online negativity and needs to come out of your phone, spreading new messages and wholesome stories of goodwill all over the world! He’s great at introducing you to new penpals with similar interests in a fun day-to-day social experience.</p>
          </section>

          <section class="text-section-2">
            <div class="content-column">
              <h3>A new good app</h3>
              <p>Using Augmented Reality technology (and some pretty convincing magic tricks), GoodGood brings positive daily experiences to the real world.</p>
              <p>Our vision is to curate a new global conversation focused around expression, participation, learning, and play through a highly engaging character-driven experience. We believe that by leveraging experimental technology in creative ways through <em>GoodGood,</em> we can create a modern, lovable, and unique character with lasting impact for all-ages.</p>
            </div>
            <img class="video-modal-button" src="res/page_content/video_thumb.png"
              @click="showVideoModal = true"
              v-if="isDesktop">
            <video controls width="100%"
              class="on-page-video"
              v-else>
              <source src="res/page_content/goodgood_trailer.mp4"
                      type="video/mp4">
              Sorry, your browser doesn't support embedded videos.
            </video>
          </section>

          <div class="letter-group-wrapper">
            <div class="letter-group"
              :class="{'letter-hover': letterHover}">
              <img class="circle-b" src="res/page_content/Circles/Circ-B.svg" alt="Circle Blue">
              <img class="circle-p" src="res/page_content/Circles/Circ-P.svg" alt="Circle Purple">
              <img class="circle-r" src="res/page_content/Circles/Circ-R.svg" alt="Circle Red">
              <img class="circle-y" src="res/page_content/Circles/Circ-Y.svg" alt="Circle Yellow">
              <a href="javascript:void(0)" class="signup-env"
                @mouseover="letterHover = true"
                @mouseout="letterHover = false"
                @click="showEmailSignupModal = true">
                <p>Sign up!</p>
                <img src="res/page_content/SignUp-Env.svg" alt="Sign Up Envelope">
              </a>
            </div>
          </div>
        </div>

        <a href="javascript:void(0)" class="app-store"
          @click="showEmailSignupModal = true">
          <img src="res/page_content/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the app store">
        </a>

        <section class="bottom">
          <div class="hr"></div>

          <img class="goodgood-logo" src="res/page_content/GG-Logov2.svg" alt="GoodGood Logo">
          <p class="content-column">By GoodGood & Friends <sup>❤️</sup></p>
          <p class="content-column">Learn about our <a href="javascript:void(0)" @click="showEmailSignupModal = true">commitment to privacy</a></p>
          <p class="through-our-app content-column">Our little team comes from wildly different creative backgrounds, producing media like you've never seen, and telling stories you're never heard. We don't want to be constrained to mobile phones — instead, our creations and technology have widespread application ranging from simple screens to installations, toys, and out walking in the real world by your side. Imagine that?</p>
          <div class="people-container">
            <img class="people" src="res/page_content/couch_people.png" alt="people">
          </div>
          <p class="copyright">&copy; 2019 GOODGOOD APP, All Rights Reserved</p>
        </section>
      </div>

      <modal class="video-modal"
        @close="showVideoModal = false"
        :vif="showVideoModal">
        <div style="width: 100%; height: 0px; position: relative; padding-bottom: 56.250%;">
          <video controls width="100%">
            <source src="res/page_content/goodgood_trailer.mp4"
                    type="video/mp4">
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </modal>

      <modal class="sign-up-modal"
        @close="showEmailSignupModal = false"
        :vif="showEmailSignupModal">
        <h3>Check back here soon!</h3>
        <p>We're working hard bringing GoodGood to life! Get emailed when everything is settled.</p>
        <p class="error"
          v-if="signUpEmailError">{{signUpEmailError}}</p>
        <div class="sign-up-modal-controls">
          <div class="loader light"
            v-if="signUpEmailSubmitting"></div>
          <input type="text"
            @keydown.enter="onSubmitEmail"
            placeholder="Type your email"
            v-model="signUpEmail">
          <button @click="onSubmitEmail"></button>
        </div>
      </modal>
    </div>
  `,
  mixins: [DesktopMixin],
  data(){
    return {
      submittedTitle: false,
      finishedTitleAnimation: false,
      name: undefined,
      loaded: false,
      scene: undefined,
      activeBubbleIndex: 0,
      renderer: noObserve(new THREE.WebGLRenderer({
        alpha: true
      })),
      letterHover: false,
      showVideoModal: false,
      showEmailSignupModal: false,
      signUpEmail: "",
      signUpEmailError: undefined,
      signUpEmailSubmitting: false,
      scrollHeight: undefined
    }
  },
  computed: {
    finishedTitle() {
      return this.finishedTitleAnimation && this.loaded;
    },
    activeBubbles(){
      let questionsWithClass = goodgoodQuestions.map((q, idx)=>{
        return {
          question : q,
          cls : idx % 2 ? "odd" : "even"
        };
      });

      let activeBubbles = questionsWithClass.slice(this.activeBubbleIndex,this.activeBubbleIndex+3);
      //If we sliced into the end of the array, add some from the front
      if(activeBubbles.length < 3) {
        activeBubbles = activeBubbles.concat(questionsWithClass.slice(0, 3-activeBubbles.length));
      }
      return activeBubbles;
    },
    showScrollDownArrow(){
      return this.scrollHeight !== undefined && //We have a scroll height (we have been mounted)
        this.scrollHeight <= 100 && //100px down the page
        !this.submittedTitle; //They haven't started the experience
    }
  },
  async mounted() {
    this.scene = window._debug_scene_snooze = await GoodGoodSnoozeScene.load(this.renderer).then(noObserve);
    setInterval(this.animateChangeBubble.bind(this), 1500);

    this.setScrollHeight();
    window.addEventListener("scroll", this.setScrollHeight.bind(this), { passive: true });
  },
  methods:{
    onLoad(){
      this.loaded = true;
    },
    onSubmit(name){
      this.submittedTitle = true;
      this.name = name;
    },
    onComplete(){
      this.finishedTitleAnimation = true;
    },
    animateChangeBubble(){
      this.activeBubbleIndex = (this.activeBubbleIndex + 1) % goodgoodQuestions.length;
    },
    setScrollHeight(){
      this.scrollHeight = document.documentElement.scrollTop || document.body.scrollTop;
    },
    async onSubmitEmail(){
      if(!this.signUpEmail.match(/.+@.+\..+/)) {
        this.signUpEmailError = "You must enter a valid email";
        return;
      }
      this.signUpEmailSubmitting = true;
      await axios.put("https://n5kbe1ghzi.execute-api.us-east-2.amazonaws.com/default/goodgood-sign-up",
        {email: this.signUpEmail});
      this.signUpEmailSubmitting = false;
      this.showEmailSignupModal = false;
    }
  }
});