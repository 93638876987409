class State {
    constructor(value) {
        this.value = value;
        this.lastSeen = undefined;
    }

    get hasSeen() {
        return !!this.lastSeen;
    }

    get sinceLastSeen(){
        return Date.now() - this.lastSeen;
    }
}

/**State machine
 */
export class SuperSimpleStateMachine {
    /**@param {string[]} states The states of this machine. This object will have a 
     * property named after each state to access information about a given state
     * @param {string} [initialState] Set an initial state with a string, otherwise it will be undefined
     */
    constructor(states, initialState=undefined){
        this._states = states.map((s)=>new State(s));
        this._history = [];

        this._states.forEach((s)=>{
            Object.defineProperty(this, s.value, {
                get(){ return s; }
            });
        });
        initialState = this._states.find((s)=>s.value === initialState);
        if(!initialState) {
            throw new Error(`Invalid initial state "${initialState}"`);
        }
        this.state = initialState;
    }

    /**@prop {State} state Setting this will set the current state (and set
     * relevant metadata). Getting it will get the current state object
     */
    set state(newState) {
        newState.lastSeen = Date.now();
        this._history.push(newState);
    }

    get state() {
        return this._history[this._history.length-1];
    }

    static factory(...args) {
        return this.bind(this, ...args);
    }
}