import { _assert, deviceOrienationToQuaternion } from "./utils.js";

export class DeviceOrientationControlsCustom {
    constructor(camera, domElement) {
        this.enabled = true;
        this.camera = camera;
        this.domElement = domElement;
        this._deviceOrientationInitialQuat;
        this._deviceOrientationLastQuat;

        window.addEventListener("deviceorientation", this._handleDeviceOrientation.bind(this));
    }

    _handleDeviceOrientation(e) {
        if(!this.enabled) {
            return;
        }

        let quat = deviceOrienationToQuaternion(e.alpha, e.beta, e.gamma);
        if(!this._deviceOrientationInitialQuat) {
            this._deviceOrientationInitialQuat = quat;
            return;
        }
        //Remove initial rotation bias from returned quaternion
        quat.premultiply(this._deviceOrientationInitialQuat.clone().conjugate());
        //Slerp the quaternion with the default rotation we want to use to basically "scale" the quaternion
        THREE.Quaternion.slerp(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,0,1), 0), quat,
            this.camera.quaternion, 0.3);

        this._deviceOrientationLastQuat = quat;
    }

    /**Sets the current view to be the 0 view
     */
    calibrate() {
        this._deviceOrientationInitialQuat = this._deviceOrientationLastQuat;
    }
}



/**Handles controls of the app, switching between mouse/touch dragging and deviceOrientation
 * @todo It would be best to remove the parameterized stuff outside of here like
 * all the orbitControls options
 */
export class GoodGoodControls {
    static get ControlScheme() { return {
        MOUSETOUCH: 0,
        DEVICEORIENTATION: 1
    }; }

    constructor(camera, domElement, controlScheme=undefined){
        this.camera = camera;
        this.domElement = domElement;
        
        this._orbitControls = new THREE.OrbitControls( camera, domElement );
        this._orbitControls.enabled = false;
        this._orbitControls.enablePan = false;
        this._orbitControls.enableZoom = false;
        this._orbitControls.minAzimuthAngle = -Math.PI/12; //[-PI,PI], 0 is middle
        this._orbitControls.maxAzimuthAngle = Math.PI/12;
        this._orbitControls.minPolarAngle = 3*Math.PI/8; //[0,PI], PI/2 is middle, 0 is top
        this._orbitControls.maxPolarAngle = 5*Math.PI/8;
        this._orbitControls.rotateSpeed = 0.05;
        this._orbitControls.target.set(0,1.5,0);

        this._deviceOrientationControls = new DeviceOrientationControlsCustom( camera, domElement );

        this.setControlScheme(controlScheme);
    }

    setControlScheme(scheme){
        _assert(scheme === GoodGoodControls.ControlScheme.MOUSETOUCH ||
            scheme === GoodGoodControls.ControlScheme.DEVICEORIENTATION ||
            scheme === undefined || scheme === null,
            "Control scheme must be valid");

        console.log("Control scheme is: " + scheme);
        this._controlScheme = scheme;
        if(scheme === GoodGoodControls.ControlScheme.MOUSETOUCH) {
            this._deviceOrientationControls.enabled = false;
            this._orbitControls.enabled = true;
            this._orbitControls.update();
        }
        else if(scheme === GoodGoodControls.ControlScheme.DEVICEORIENTATION) {
            this._orbitControls.enabled = false;
            this._deviceOrientationControls.enabled = true;
            this._deviceOrientationControls.calibrate();
        }
        else { //undefined || null
            this._orbitControls.enabled = false;
            this._deviceOrientationControls.enabled = false;
        }
    }

    update() {
        if(this._controlScheme === GoodGoodControls.ControlScheme.MOUSETOUCH) {
            this._orbitControls.update();
        }
    }
}