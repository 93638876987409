import Vue from "vue";

Vue.component("goodgood-text-animated", {
  template: `
  <div class="goodgood-text-animated"
     ref="goodGoods">
    <span v-for="(word, idx) in goodGoods"
      class="word"
      v-show="idx === currentGoodGood || idx === nextGoodGood(currentGoodGood)">
      <span v-for="letter in word.split('')"
        class="letter"
        v-text="letter">
      </span>
    </span>
  </div>
  `,
  data(){
    return {
      goodGoods: [
        "GoodGood",
        "良い良い",
        "BienBien",
        "جيد جيد",
        "Bene Bene",
        "好好",
        "GutGut",
        "좋아 좋아",
        "GoodGood",
        "Хорошо Xорошо",
        "Maikaʻi Maikaʻi",
        "अच्छा अच्छा",
        "Goed Goed",
        "ДобреДобре",
      ],
      currentGoodGood: 0
    };
  },
  mounted(){
    setInterval(this.animateChangeWord.bind(this), 3000);
  },
  methods:{
    nextGoodGood(index) {
      return (this.currentGoodGood + 1) % this.goodGoods.length;
    },
    animateChangeWord(){
      this.currentGoodGood = this.nextGoodGood(this.currentGoodGood);
      let words = Array.from(this.$refs.goodGoods.children);
      let currentWordEl = words[this.currentGoodGood];
      let nextWordEl = words[(this.currentGoodGood + 1) % this.goodGoods.length];

      Array.from(currentWordEl.children).forEach((letteEl, idx)=>{
        setTimeout(()=>letteEl.className = 'letter out', idx*80);
      });
      Array.from(nextWordEl.children).forEach((letteEl, idx)=>{
        letteEl.className = 'letter behind';
        setTimeout(()=>letteEl.className = 'letter in', 340+(idx*80));
      });
    }
  }
});