import * as THREE from "three";
import "./THREE.additions.js";
import "three-examples/loaders/ColladaLoader.js";
import "three-examples/controls/OrbitControls.js";
import "three-examples/shaders/CopyShader.js";
import "three-examples/shaders/FXAAShader.js";
import "three-examples/postprocessing/EffectComposer.js";
import "three-examples/postprocessing/SSAARenderPass.js";
import "three-examples/postprocessing/RenderPass.js";
import "three-examples/postprocessing/TAARenderPass.js";
import "three-examples/postprocessing/ShaderPass.js";

import { GradientBox } from "./GradientBox.js";
import { loadDAE } from "./utils.js";

const playerFOV = 45;

/**The main scene but GoodGood is snoozing
 */
export class GoodGoodSnoozeScene extends THREE.Scene {
  constructor(renderer){
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFShadowMap;
    super();
    this._renderer = renderer;
    // camera
    const rect = renderer.domElement.getBoundingClientRect();
    const aspect = rect.width / rect.height;
    this._camera = new THREE.PerspectiveCamera(playerFOV, aspect, 0.05, 50);
    this.camera.position.set( 1.25, 3.75, 4.3 );
    this.camera.quaternion.setFromAxisAngle(new THREE.Vector3(1,0,0),-Math.PI/7);

    // composer
    this.composer = new THREE.EffectComposer( renderer );
    const renderPass = new THREE.RenderPass( this, this.camera );
    this.composer.addPass( renderPass );
    const taaRenderPass = this.taaRenderPass = new THREE.TAARenderPass( this, this.camera );
    taaRenderPass.renderToScreen = true;
    /*'Level 0: 1 Sample': 0,
      'Level 1: 2 Samples': 1,
      'Level 2: 4 Samples': 2,
      'Level 3: 8 Samples': 3,
      'Level 4: 16 Samples': 4,
      'Level 5: 32 Samples': 5
      */
    taaRenderPass.sampleLevel = 3;
    this.composer.addPass( taaRenderPass );

    // ambient light
    let am_light = new THREE.AmbientLight( 0xBBBBBB );
    this.add( am_light );
    // directional light
    let dir_light = new THREE.DirectionalLight( 0xFFFFFF );
    dir_light.position.set(3,10,3);
    dir_light.target.position.set(-3,-10,-3);
    dir_light.castShadow = true;
    dir_light.shadow.camera.left = -30;
    dir_light.shadow.camera.top = -30;
    dir_light.shadow.camera.right = 30;
    dir_light.shadow.camera.bottom = 30;
    dir_light.shadow.camera.near = 0.1;
    dir_light.shadow.camera.far = 60;
    dir_light.shadow.bias = -.005;
    dir_light.shadow.mapSize.width = dir_light.shadow.mapSize.height = 2048;
    this.add( dir_light );
    this.add( dir_light.target );
    //var dir_light_helper = new THREE.DirectionalLightHelper( dir_light );
    //this.add( dir_light_helper );

    //Color gradient box
    let plane = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(20,20,1),
      new THREE.ShadowMaterial({
        opacity: 0.3
      })
    );
    plane.quaternion.setFromAxisAngle(new THREE.Vector3(1,0,0), -Math.PI/2);
    plane.position.set(0,-1.5,-4);
    plane.receiveShadow = true;
    plane.castShadow = true;
    this.add(plane);

    //Async assets
    this._asyncLoaded = this._loadAssets({
      ggLoungingDAE: async ()=>await loadDAE("res/GG_Animations/GG_Lounging4_combined.dae")
    });

    this._lastTime = Date.now();
  }

  static async load(...args){
    let tmp = new GoodGoodSnoozeScene(...args);
    console.log("begin load");
    await tmp._asyncLoaded;
    console.log("end load");
    return tmp;
  }

  async _loadAssets(assets){
    //Capture output as the original key in the assets nd do some post processing
    Object.keys(assets).forEach((k)=>{
      assets[k] = assets[k]()
        .then((o)=>{
          assets[k] = o;
          if(o.isObject3D) {
            o.name = k;
          }
        }).catch((err)=>{
          assets[k] = err;
          console.error(err);
        });
    });

    //Await their load
    await Promise.all(Object.values(assets));
    const { ggLoungingDAE } = assets;
    let ggLounging = ggLoungingDAE.scene.children[0];
    this.ggLounging = ggLounging;
    this.ggLounging.traverse((obj)=>{
      obj.receiveShadow = true;
      obj.castShadow = true;
    });
    this.add(ggLounging);
    ggLounging.position.set(2,-1,-2);
    ggLounging.quaternion.copy(
        new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,1,0),-Math.PI/8)
            .premultiply(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0),Math.PI)));
    ggLounging.scale.set(0.5,0.5,0.5);
    this.mixer = new THREE.AnimationMixer( ggLounging );
    let loungingAction = this.mixer.clipAction( ggLoungingDAE.animations[0] );
    loungingAction.play();
    return assets;
  }

  get camera(){
    return this._camera;
  }
  
  setSize(width, height){
    this.composer.setSize( width, height );
    this.taaRenderPass.setSize( width, height );
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }

  render(){
    let time = Date.now();
    let deltaTime = time - this._lastTime;
    this._lastTime = time;

    if(this.mixer) {
      this.mixer.update(deltaTime/1000);
    }
    this.composer.render();
  }
}