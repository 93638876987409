import axios from "axios";
import dialog from "./dialog.js";
import { LocalizationCopy } from "./LocalizationCopy.js";
export const copy = new LocalizationCopy( LocalizationCopy.fromDotList(dialog) );

let _lastPath;
export function getRandomGoodGoodLetter(name, location){
  let letterPhrasesPaths = [
    "character.greeting\\d+",
    "character.greeting.name\\d+",
    "character.greeting.location\\d+",
    "character.greeting.locationname\\d+",
    "character.greeting.nameint\\d+",
    "character.greeting.inspoquote\\d+"
  ];
  let path = _lastPath;
  while(_lastPath === path) { //Never use the direct last path
    path = copy.randomPathInGroup(letterPhrasesPaths);
  }
  _lastPath = path;

  let phrase = copy.get(path);
  return {
    name: "GoodGood",
    city: "Notification Central",
    entry: LocalizationCopy.sprintfish(phrase, { name, location, int: Math.floor(Math.random()*5+2) }, path.split(".").pop())
  };
}

export function getRandomWelcomeMessage(name) {
  let date = new Date();
  let startPath;
  if(date.hour <= 11) {
    startPath = "character.welcome.morning";
  }
  else if(date.hour <= 20) { //8pm
    startPath = "character.welcome.afternoon";
  }
  else { //11:59pm
    startPath = copy.randomPathInGroup([
      "character.welcome.night1",
      "character.welcome.night2"
    ]);
  }

  let path = copy.randomPathInGroup(["character.arkit.mailtime.newletters"]);
  let startPhrase = copy.get(startPath);
  let phrase = LocalizationCopy.sprintfish(copy.get(path), {name}, ["name"]); //Maximum one parameter name, if any
  return `${startPhrase} ${phrase}`;
}

export async function getAPILetter() {
  let { data } = await axios.get("https://n5kbe1ghzi.execute-api.us-east-2.amazonaws.com/default/goodgood-entries");
  return data[0];
}

export async function putAPILetter(name, city, entry) {
  await axios.put("https://n5kbe1ghzi.execute-api.us-east-2.amazonaws.com/default/goodgood-entries",
    {name, city, entry});
}

export async function getRandomLetterCopy(name, location) {
  if(Math.random()>0.5) {
    return await getAPILetter();
  }
  else {
    return getRandomGoodGoodLetter(name, location);
  }
}