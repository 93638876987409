import * as THREE from "three";
import { _assert } from "./utils.js";

/**Convert a hex string to a [0.0,1.0] rgb color float array.
 * @param {string} hex A '#' prefix'd hex string
 * @returns {Number[]} A three component array of [0.0,1.0] bounded numbers for rgb
 */
function _2rgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return [
        parseInt(result[1], 16)/255,
        parseInt(result[2], 16)/255,
        parseInt(result[3], 16)/255
    ];
}

export class GradientBox extends THREE.Mesh {
    constructor() {
        let geo = new THREE.BoxBufferGeometry(1,1,1);
        let colors = [
            //These are slightly out of date
            //I got the 0-3 windings wrong
            //7,12 #033EA2
            //2,5,6,10,13,14,23 #1259D3
            //1,3,4,8,11,15,21,22 #478EFF
            //0,9,20 #3478FA
            //RIGHT
            _2rgb("#478EFF"), //0
            _2rgb("#3478FA"), //1
            _2rgb("#1259D3"), //2
            _2rgb("#478EFF"), //3
            //LEFT
            _2rgb("#478EFF"), //4
            _2rgb("#1259D3"), //5
            _2rgb("#1259D3"), //6
            _2rgb("#033EA2"), //7
            //TOP
            _2rgb("#478EFF"), //8
            _2rgb("#3478FA"), //9
            _2rgb("#1259D3"), //10
            _2rgb("#478EFF"), //11
            //BOTTOM
            _2rgb("#033EA2"), //12
            _2rgb("#1259D3"), //13
            _2rgb("#1259D3"), //14
            _2rgb("#478EFF"), //15
            //FRONT
            _2rgb("#033EA2"), //16
            _2rgb("#033EA2"), //17
            _2rgb("#033EA2"), //18
            _2rgb("#033EA2"), //19
            //BACK
            _2rgb("#3478FA"), //20
            _2rgb("#478EFF"), //21
            _2rgb("#478EFF"), //22
            _2rgb("#1259D3"), //23
        ].reduce((acc, item) => acc.concat(item), []);
        _assert(colors.length/3 === geo.attributes.position.count, "Vertex colors was not the same as vertex length");
        geo.addAttribute( 'color', new THREE.BufferAttribute( new Float32Array( colors ), 3 ) );
        //Adjust triangle windings of specific faces to make the vertex colors
        //not look shitty
        //RIGHT TRIANGLES
        geo.getIndex().array[0] = 0;
        geo.getIndex().array[1] = 3;
        geo.getIndex().array[2] = 1;
        geo.getIndex().array[3] = 0;
        geo.getIndex().array[4] = 2;
        geo.getIndex().array[5] = 3;

        //Vertex color material
        let mat = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            flatShading: false,
            vertexColors: THREE.VertexColors,
            side: THREE.BackSide //Invert it
        });
        super(geo, mat);
        this.name = this.constructor.name;
    }
}