import Vue from "vue";

Vue.component("goodgood-card", {
  template: `
  <div class="goodgood-card"
    ref="card">
    <div class="content" ref="content">
      <slot></slot>
    </div>
  </div>
  `,
  props: {
    autoResize: {
      type: Boolean,
      default: true
    }
  },
  mounted(){
    window.addEventListener('resize', ()=>{
      this.resizeCard();
    });
    this.resizeCard();

    //Mutation observer on the slot, resizeCard on when it changes
    this.observer = new MutationObserver(this.resizeCard.bind(this));
    this.observer.observe(this.$refs.content, { characterData: true, childList: true, subtree: true });
  },
  beforeDestroy: function() {
    //Clean up observer
    this.observer.disconnect();
  },
  methods:{
    resizeCard(){
      if(!this.autoResize) {
        return;
      }

      function rmPx(px){ return parseFloat(px.slice(0,-2)); }

      //Take the containers max height and make the text height smaller based on it's given
      //font size.
      //Also make sure it doesn't shrink below the container min height ratio
      //...this probably causes like 3 repaints
      let container = this.$refs.card;
      let containerMinHeight = rmPx(window.getComputedStyle(container).minHeight);
      let containerMaxHeight = rmPx(window.getComputedStyle(container).maxHeight);

      //Get height ratio for all elements
      let content = this.$refs.card.querySelector(".content");
      let texts = content.children;
      Array.from(texts).forEach((text)=>{
        text.removeAttribute("style");
      });
      let totalHeight = content.getBoundingClientRect().height;
      let heightRatio = Math.min(containerMaxHeight / totalHeight, 1); //Only shrink, never grow

      Array.from(texts).forEach((text)=>{
        let textFontSize = rmPx(window.getComputedStyle(text).fontSize);
        text.style.fontSize = textFontSize * heightRatio + "px";
      });
    }
  }
});